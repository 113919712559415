/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
import moment from 'moment'
import html2canvas from 'html2canvas'
import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

export const msEpochToDate = value => {
  if (!value) return ''
  return `${moment(value).format('HH:mm:ss')} / ${moment(value).format('DD MMM YY')}`
}

export const isConnected = value => {
  if (!value) return false
  const start = Date.now()
  const utcnow = moment.utc(start)
  return (utcnow - value) < 600000 // 5 min agao
}
/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const sharpen = (ctx, w, h, mix) => {
  const weights = [0, -1, 0, -1, 5, -1, 0, -1, 0]
  const katet = Math.round(Math.sqrt(weights.length))
  // eslint-disable-next-line no-bitwise
  const half = (katet * 0.5) | 0
  const dstData = ctx.createImageData(w, h)
  const dstBuff = dstData.data
  const srcBuff = ctx.getImageData(0, 0, w, h).data
  let y = h

  while (y--) {
    let x = w
    // eslint-disable-next-line no-plusplus
    while (x--) {
      const sy = y
      const sx = x
      const dstOff = (y * w + x) * 4
      let r = 0
      let g = 0
      let b = 0

      // eslint-disable-next-line no-plusplus
      for (let cy = 0; cy < katet; cy++) {
        for (let cx = 0; cx < katet; cx++) {
          const scy = sy + cy - half
          const scx = sx + cx - half

          if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
            const srcOff = (scy * w + scx) * 4
            const wt = weights[cy * katet + cx]

            r += srcBuff[srcOff] * wt
            g += srcBuff[srcOff + 1] * wt
            b += srcBuff[srcOff + 2] * wt
          }
        }
      }

      dstBuff[dstOff] = r * mix + srcBuff[dstOff] * (1 - mix)
      dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * (1 - mix)
      dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * (1 - mix)
      dstBuff[dstOff + 3] = srcBuff[dstOff + 3]
    }
  }

  ctx.putImageData(dstData, 0, 0)
}

export const printThis = element => {
  html2canvas(element).then(canvas => {
    // create intermediate canvas
    const rotCanvas = document.createElement('canvas')
    // swap width and height
    rotCanvas.width = canvas.height
    rotCanvas.height = canvas.width
    // get context
    const rctx = rotCanvas.getContext('2d')
    // translate to center (rotation pivot)
    rctx.translate(rotCanvas.width * 0.5, rotCanvas.height * 0.5)
    // rotate -90° (CCW)
    rctx.rotate(-Math.PI * 0.5)
    // draw image offset so center of image is on top of pivot
    rctx.drawImage(canvas, -canvas.width * 0.5, -canvas.height * 0.5)
    sharpen(rctx, rotCanvas.width, rotCanvas.height, 1)
    // extract image from rotate canvas
    const imgData = rotCanvas.toDataURL('image/png')
    // var imgData = canvas.toDataURL('image/png');
    const printWindow = window.open('', 'PRINT', 'height=1000,width=850')
    printWindow.document.write('<html><head>')
    printWindow.document.write('<style>@media print {body { width:8.5cm; height: 10cm; }}</style>')
    printWindow.document.write('</head><body >')
    printWindow.document.write('<body onload="printThisPage()" style="background-color: white;">')
    // eslint-disable-next-line prefer-template
    printWindow.document.write('<img src="' + imgData + '" style="width:100%">')
    printWindow.document.write('</body></html>')
    printWindow.document.addEventListener('DOMContentLoaded', () => {
      printWindow.document.close() // necessary for IE >= 10
      printWindow.focus() // necessary for IE >= 10*/
      printWindow.print()
      printWindow.close()
    })
  })
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
